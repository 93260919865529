import React, { useEffect, useRef, useState } from 'react';
import { Grid }                               from '@material-ui/core';
import { Trans, useTranslation }              from 'react-i18next';
import moment                                 from 'moment';
import RichAlert                              from '../../../feeback/RichAlert';
import Legend                                 from './Legend';
import Controls                               from './control/Controls';
import Button                                 from '../../../inputs/Button';
import Editor                                 from './Editor';
import { formatDuration, getTimeCodeFormat }  from '../../../../utils/lyrics';
import { connect }                            from 'react-redux';
import {fetchLyrics, forceLyrics, updateLyrics} from '../../../../../store/actions/discography/LyricsAction';
import { Link }                               from 'react-router-dom';
import { getMusicRecordEditRoute }            from '../../../../../router/routes/music/factory/records';
import {error, success} from "../../../../../store/actions/system/ToastAction";

const Edit = ({ record, fullHour, lyrics, loading, saving, fetchLyrics, updateLyrics, forceLyrics }) => {
	const { t } = useTranslation();
	const mediaRef = useRef(null);
	const editorRef = useRef(null);

	const [selected, setSelected] = useState(-1);
	const [cache, setCache] = useState([]);

	const [hasOverflow, setHasOverflow] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [textDisabled, setTextDisabled] = useState(false);
	const [timeCodeDisabled, setTimeCodeDisabled] = useState(false);

	const [warnSingleEdit, setWarnSingleEdit] = useState(false);

	const init = (record) => {
		if (record.Status !== 'released') {
			setTextDisabled(false);
			setTimeCodeDisabled(false);
			setWarnSingleEdit(false);
		} else {
			// Edit only allowed if
			//   if no lyrics (in that case warn that the your will be able to edit only once)
			//    OR
			//   if not all timecodes are set (in that case only the timecodes are editable)
			if (record.Lyrics === 'empty') {
				setTextDisabled(false);
				setTimeCodeDisabled(false);
				setWarnSingleEdit(true);
			} else if (record.Lyrics === 'lyrics') { // Not all timecodes set
				setTextDisabled(true);
				setTimeCodeDisabled(false);
				setWarnSingleEdit(true);
			} else { // we cannot edit anything
				setTextDisabled(true);
				setTimeCodeDisabled(true);
				setWarnSingleEdit(false);
			}
		}
	}

	useEffect(() => {
		fetchLyrics(record.Music_Label_Record__);
		init(record)
	}, [fetchLyrics, record]);

	useEffect(() => {
		let hasEmpty = false;
		let hasFilled = false;
		for (let i = 0; i < cache.length; i++) {
			if (cache[i].info.timeCode !== null) hasFilled = true;
			else hasEmpty = true;
			if (hasFilled && hasEmpty) { // we cannot have both
				setSaveEnabled(false);
				return;
			}
		}

		setSaveEnabled(true);
	}, [setSaveEnabled, cache]);

	const setTimeCode = (idx, timeCode) => {
		if (idx < 0 || idx >= cache.length) return;
		cache[idx].info.timeCode = timeCode ? timeCode.asSeconds() : null;
		const formatted = timeCode ? formatDuration(timeCode) : null;
		const text = getTimeCodeFormat(formatted, fullHour);
		cache[idx].info.timeCodeRef.innerText = text;
		cache[idx].info.timeCodeFormatted = text;
		setCache([...cache]);
	};

	const onTimeCodeClick = seconds => {
		mediaRef.current.currentTime = seconds;
	};

	const indexLookupByTimeCode = (timeCode) => {
		if (cache.length < 1) return -1;
		let current = 0;
		let nullFound = null;

		do {
			if (cache[current].info.timeCode === null && nullFound === null) {
				nullFound = current;
				current++;
				continue;
			}
			if (cache[current].info.timeCode !== null && cache[current].info.timeCode > timeCode) {
				if (nullFound !== null) return nullFound;
				return current;
			}
			if (nullFound !== null && cache[current].info.timeCode !== null) {
				nullFound = null;
			}
			current++;
		} while (current < cache.length);

		if (nullFound !== null) return nullFound;
		return current;
	};

	const onMediaTimeChange = () => {
		if (cache.length < 1) return; // no lyrics nothing to do

		const currentTime = mediaRef.current.currentTime;
		const idx = indexLookupByTimeCode(currentTime);
		setSelected(idx);
	};

	const handleTimingSet = timingAsSecond => {
		handleTimeEdited(selected, timingAsSecond);
	};

	const handleTimingUnset = (index) => {
		handleTimeEdited(index, null);
	};

	const handleTimeEdited = (index, timingAsSecond) => {
		if (index === -1) return;
		let duration = null;
		if (timingAsSecond !== null) {
			const rounded = Math.round(timingAsSecond * 1000) / 1000;
			duration = moment.duration().add(rounded, 'second');
		}
		setTimeCode(index, duration);
		const currentTime = mediaRef.current.currentTime;
		const idx = indexLookupByTimeCode(currentTime);
		setSelected(idx);
	};

	const handleSave = () => {
		const result = [];
		cache.forEach(c => {
			result.push({
				Music_Label_Record_Lyric__: c.id ?? null,
				Text: c.content.text,
				TimeCode: c.info.timeCode ? formatDuration(moment.duration(c.info.timeCode, 'seconds')) : null,
			});
		});

		updateLyrics(record.Music_Label_Record__, result);
	};

	const handleCopy = () => {
		const result = [];
		cache.forEach(c => {
			result.push(c.info.timeCode ? formatDuration(moment.duration(c.info.timeCode, 'seconds')) : '');
			result.push(c.content.text);
		});
		navigator.clipboard.writeText(result.join('\n')).then(() => {
			success('record_lyrics_copied_to_clipboard');
		})
	}

	const handlePaste = () => {
		let newCache = [];
		navigator.clipboard
			.readText()
			.then((clipText) => {
				const data = clipText.split(/\r?\n|\r|\n/g);
				if((data.length % 2) !== 0) {
					error('invalid_lyrics_format');
				}
				let j = 0;
				newCache = ((data.length/2)<cache.length) ? cache.slice(0, data.length/2) : [...cache]
				for(let i =0; i<data.length;i+=2) {
					let timeCode = data[i] === '' ? null : data[i]
					let text = data[i+1]
					if (timeCode!==null) {
						timeCode = moment.duration(timeCode, 'seconds')
						if (!timeCode.isValid()) {
							error('invalid_lyrics_format');
							return;
						}
					}

					if(j > (cache.length -1)) {
						newCache.push(
							{
								content: {text:text, ref:null},
								id:null,
								info:{
									overflowRef:null,
									ref:null,
									timeCode:null,
									timeCodeFormatted:null,
									timeCodeRef:null
								}
							}
						)
					}

					newCache[j].info.timeCode = timeCode ? formatDuration(moment.duration(timeCode, 'seconds')) : null;
					newCache[j].info.timeCodeFormatted = formatDuration(moment.duration(timeCode, 'seconds'));
					newCache[j].content.text = text
					j+=1
				}

				editorRef.current.reInitFromCacheData(newCache)
				success('record_lyrics_copied_from_clipboard');

			});
	}

	return (
		<Grid container spacing={3} style={{ position: 'relative' }}>
			<Grid item xs={12}>
				<RichAlert severity={hasOverflow ? 'warning' : 'info'}>
					<Trans i18nKey='record_lyrics_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>

			{(textDisabled && timeCodeDisabled) && <Grid item xs={12}>
				<RichAlert severity='info'>
					<Trans i18nKey='record_released_lyrics_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>}

			<Grid item xs={12}>
				<Grid container spacing={3} justifyContent="space-between">
					<Grid item>
						<Legend/>
					</Grid>
					<Grid item>
						<Grid container spacing={3}>
							<Grid item>
								<Button
									color='primary'
									variant='outlined'
									onClick={handleCopy}
								>
									{t('copy_lyrics_btn')}
								</Button>
							</Grid>
							<Grid item>
								<Button
									color='primary'
									variant='outlined'
									onClick={handlePaste}
								>
									{t('paste_lyrics_btn')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

			</Grid>
			<Grid item xs={12}>
				<Editor
					ref={editorRef}
					lyrics={lyrics}
					cache={cache}
					setCache={setCache}
					setHasOverflow={setHasOverflow}
					onTimeCodeClick={onTimeCodeClick}
					fullHour={fullHour}
					selected={selected}
					textDisabled={textDisabled}
					disabled={saving || loading || (textDisabled && timeCodeDisabled)}
					loading={loading || saving}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controls
					timingDisabled={timeCodeDisabled}
					disabled={saving || loading}
					onTimeEdited={handleTimeEdited}
					fullHour={fullHour}
					selected={selected}
					cache={cache}
					record={record}
					onTimeUnset={handleTimingUnset}
					onTimeUpdate={onMediaTimeChange}
					onTimingSet={handleTimingSet}
					ref={mediaRef}
				/>
			</Grid>

			<Grid item xs={12}>
				<RichAlert severity={saveEnabled ? 'info' : 'warning'}>
					<Trans i18nKey='record_lyric_save_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
			{warnSingleEdit && <Grid item xs={12}>
				<RichAlert severity={'warning'}>
					<Trans i18nKey='record_lyric_save_single_warn'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>}
			<Grid item xs={12}>
				<Grid container justify='space-between'>
					<Grid item>
						<Button
							variant='outlined'
							component={Link}
							to={getMusicRecordEditRoute(record.Music_Label_Record__)}
							disabled={loading || saving}
						>
							{t('back_btn')}
						</Button>
					</Grid>
					<Grid item>
						<Button
							color='primary'
							variant='contained'
							loading={saving}
							onClick={handleSave}
							disabled={!saveEnabled || hasOverflow || loading || saving || (textDisabled && timeCodeDisabled)}
						>
							{t('save_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		lyrics: state.lyrics.lyrics,
		loading: state.lyrics.lyricsLoading,
		saving: state.lyrics.updating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		forceLyrics: (data) => dispatch(forceLyrics(data)),
		fetchLyrics: (recordId) => dispatch(fetchLyrics(recordId)),
		updateLyrics: (recordId, newLyrics) => dispatch(updateLyrics(recordId, newLyrics)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
