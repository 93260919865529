import {
	LYRICS_FETCH,
	LYRICS_FETCH_DONE,
	LYRICS_UPDATE,
	LYRICS_UPDATE_DONE
}                      from '../../reducers/discography/LyricsReducer';
import { handleError } from '../../../components/utils/apiErrorHandler';
import { success }     from '../system/ToastAction';
import { rest }        from '@karpeleslab/klbfw';

export const forceLyrics = (data) => {
	return (dispatch, getState) => {
		return new Promise((resolve,r) => {
			dispatch({ type: LYRICS_FETCH_DONE, lyrics: data });
			resolve()
		})
	};
};

export const fetchLyrics = (recordId) => {
	return (dispatch, getState) => {
		dispatch({ type: LYRICS_FETCH });
		return rest('Music/Label/Record/' + recordId + '/Lyric',)
			.then(({ data }) => {
				dispatch({ type: LYRICS_FETCH_DONE, lyrics: data });
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateLyrics = (recordId, newLyrics) => {
	return (dispatch, getState) => {
		dispatch({ type: LYRICS_UPDATE });
		return rest('Music/Label/Record/' + recordId + '/Lyric:set', 'POST', { lyrics: newLyrics })
			.then(({ data }) => {
				success('record_lyrics_updated');
				dispatch({ type: LYRICS_UPDATE_DONE, lyrics: data.lyrics, record: data.record });
			})
			.catch((err) => {
				dispatch({ type: LYRICS_UPDATE_DONE });
				handleError(getState, dispatch, err);
			});
	};
};
