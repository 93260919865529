import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import Box                                                                              from '@material-ui/core/Box';
import { makeStyles }                                                                   from '@material-ui/core';
import eaw                                                                              from 'eastasianwidth';
import moment                                                                           from 'moment';
import { useTranslation }                                                               from 'react-i18next';
import { getLineElement, getLineId, getLineTimeCodeId, getTimeCode, getTimeCodeFormat } from '../../../../utils/lyrics';
import { usePrevious }                                                                  from '../../../../utils/hooks';
import Loading
                                                                                        from '../../../feeback/loading/Loading';
import Backdrop
                                                                                        from '@material-ui/core/Backdrop';

const lineHeight = '1.7';
const fontSize = '0.975rem';
const fontWeight = '400';
const letterSpacing = '0.03071em';
const family = '"Roboto", "Helvetica", "Arial", sans-serif;';
const lineContainerWidth = 200;

const useStyle = makeStyles((theme) => {
	return {
		container: {
			position: 'relative',
			width: '100%',
			height: '500px',
			border: '1px solid #000000',
			overflowX: 'auto',
			overflowY: 'hidden'
		},
		lineContainer: {
			height: '100%',
			width: `${lineContainerWidth}px`,
			background: theme.palette.background.default,
			fontSize: fontSize,
			fontFamily: family,
			fontWeight: fontWeight,
			lineHeight: lineHeight,
			letterSpacing: letterSpacing,
			paddingTop: '10px',
			overflow: 'hidden',
		},
		common: {
			fontWeight: fontWeight,
			letterSpacing: letterSpacing,
			fontSize: fontSize,
			lineHeight: lineHeight,
			fontFamily: family,
			width: 'auto',
			minWidth: `calc(100% - ${lineContainerWidth}px);`,
			height: '100%',
			position: 'absolute',
			top: '0',
			left: '0',
			background: 'transparent',
			padding: '10px',
			marginLeft: `${lineContainerWidth}px`,
			border: 'none',
			overflow: 'auto',
			outline: 'none',
			boxShadow: 'none',
		},
		div: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			resize: 'none',
		},
		textarea: {
			caretColor: theme.palette.text.primary,
			color: theme.palette.text.primary,
			resize: 'none',
			height: '100%',
			paddingBottom: 0,
			'&:disabled': {
				whiteSpace: 'pre',
			}
		},
		textOverflow: {
			background: '#e0e0e0',
			color: 'white'
		},
		lineNumber: {
			position: 'relative',
			width: '100%',
			fontFamily: 'Courier New',
			transform: 'scaleX(.9)',
			fontWeight: 700,
			'&.selected': {
				background: theme.palette.text.disabled,
				color: 'white',
			},
			'&.current-reading': {
				background: theme.palette.primary.main,
				color: 'white',
			},
			'&:hover': {
				cursor: 'pointer'
			},
			'& > .time-code': {
				position: 'absolute',
				right: '25px',
			},
			'& > .warning': {
				position: 'absolute',
				right: '5px',
				color: theme.palette.warning.main,
			},
			'& > .number': {
				position: 'relative',
				display: 'inline-block',
				width: '50px',
				textAlign: 'right'
			}
		},
		loader: {
			zIndex: 999,
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0
		},

	};
});

const Editor = forwardRef(({ lyrics, cache, setCache, setHasOverflow, onTimeCodeClick, fullHour, selected, disabled = false, textDisabled = false, loading = false }, parentRef) => {
	const classes = useStyle();
	const { t } = useTranslation();
	const divRef = useRef(null);
	const lineRef = useRef(null);
	const textRef = useRef(null);

	const previousSelected = usePrevious(selected);

	function reInitFromCacheData (cacheData) {
		lineRef.current.innerHtml = '';
		divRef.current.innerHtml = '';
		textRef.current.value = '';

		let textAreaContent = '';
		let hasOverflow = false;
		const newCache = []
		cacheData.forEach((l, idx) => {
			const t = l.content.text
			textAreaContent += `${t}\n`;
			const timeCode = l.info.timeCode
			const text = t.replace(/ /g, '\u00a0');
			// Need to remove previous
			if(l.info.ref) l.info.ref.remove();
			if(l.info.timeCodeRef) l.info.timeCodeRef.remove();
			if(l.info.overflowRef) l.info.overflowRef.remove();
			if(l.content.ref) l.content.ref.remove();

			const [cacheEntry, overflow] = buildLineHtml(text, idx, timeCode, true);
			if (cacheEntry) {
				cacheEntry.id = l.id;
				newCache.push(cacheEntry);
			}else {
				newCache.push(l)
			}
			hasOverflow = hasOverflow || overflow;
		});

		if (textAreaContent) // remove the last \n
			textAreaContent = textAreaContent.slice(0, -1);

		if (cacheData.length < cache.length) { // need to remove data from cache
			for (let i = cacheData.length; i<cache.length;i++) {
				if(cache[i].info.ref) cache[i].info.ref.remove();
				if(cache[i].info.timeCodeRef) cache[i].info.timeCodeRef.remove();
				if(cache[i].info.overflowRef) cache[i].info.overflowRef.remove();
				if(cache[i].content.ref) cache[i].content.ref.remove();
			}
		}

		textRef.current.value = textAreaContent;

		setCache([...newCache]);
		setHasOverflow(hasOverflow);
	}

	useImperativeHandle(parentRef, () => {
		return {
			reInitFromCacheData,
		}
	})

	useEffect(() => {
		// initialize
		lineRef.current.innerHtml = '';
		divRef.current.innerHtml = '';

		let textAreaContent = '';
		let hasOverflow = false;
		lyrics.forEach((l, idx) => {
			textAreaContent += `${l.Text}\n`;
			const text = l.Text.replace(/ /g, '\u00a0');
			const [cacheEntry, overflow] = buildLineHtml(text, idx, l.TimeCode);
			if (cacheEntry) {
				console.log("new cache entry")
				cacheEntry.id = l.Music_Label_Record_Lyric__;
				cache.push(cacheEntry);
			}
			hasOverflow = hasOverflow || overflow;
		});

		if (textAreaContent) // remove the last \n
			textAreaContent = textAreaContent.slice(0, -1);

		textRef.current.value = textAreaContent;

		setCache([...cache]);
		setHasOverflow(hasOverflow);
		// eslint-disable-next-line
	}, [lyrics]);

	useEffect(() => {
		let selectedElement = null;

		const prev = getLineElement(previousSelected);
		if (prev)
			prev.classList.remove('selected');

		const previousSibling = getLineElement(previousSelected - 1);
		if (previousSibling)
			previousSibling.classList.remove('current-reading');

		const current = getLineElement(selected);
		if (current) {
			selectedElement = current;
			current.classList.add('selected');
			if (current.previousSibling) {
				current.previousSibling.classList.add('current-reading');
			}
		} else {
			// maybe the last one, let's try to check that and if so mark the last one as reading
			if (selected - 1 < 0) return;
			const current = getLastInfoLine();
			if (!current) return;
			selectedElement = current;
			current.classList.add('current-reading');
		}

		if (selectedElement) {
			let newScroll = null;
			let container = lineRef.current;
			let pos = (selectedElement.offsetTop) - (container.scrollTop + 10);
			if (pos < 0) {
				newScroll = container.scrollTop + pos;

			} else {
				var offset = (pos + selectedElement.offsetHeight) - (container.offsetHeight - 10);
				if (offset > 0) {
					newScroll = container.scrollTop + offset;
				}
			}

			if (newScroll !== null) {
				divRef.current.scrollTop = newScroll;
				lineRef.current.scrollTop = newScroll;
				textRef.current.scrollTop = newScroll;
			}
		}

		// eslint-disable-next-line
	}, [selected]);

	const getLastInfoLine = () => {
		const list = document.querySelectorAll(`.${classes.lineNumber}:last-child`);
		if (list.length < 1) return null;
		return list[0];
	};

	const buildContent = (ref, l, overflow) => {
		if (!overflow) {
			ref.childNodes[0].innerText = l;
			ref.childNodes[1].innerText = '';
		} else {
			ref.childNodes[0].innerText = eaw.slice(l, 0, 32);
			ref.childNodes[1].innerText = overflow;
		}
	};

	const buildOverflow = () => {
		const overflowElement = document.createElement('span');
		overflowElement.className = 'warning';
		overflowElement.title = t('record_lyrics_too_long');
		overflowElement.innerHTML = '\t&#9888;';
		return overflowElement;
	};

	const buildInfo = (idx, timeCode, overflow, selected) => {
		const infoContainer = document.createElement('div');
		infoContainer.id = getLineId(idx);
		infoContainer.dataset.idx = idx;
		infoContainer.className = `${classes.lineNumber} ${selected === idx ? 'selected' : ''}`;

		const number = document.createElement('span');
		number.className = 'number';
		number.innerText = `${idx + 1}.`;

		const timeCodeElement = document.createElement('span');
		timeCodeElement.className = 'time-code';
		timeCodeElement.id = getLineTimeCodeId(idx);
		timeCodeElement.innerText = getTimeCodeFormat(timeCode, fullHour);

		infoContainer.appendChild(number);
		infoContainer.appendChild(timeCodeElement);

		let overflowElement = null;
		if (overflow) {
			overflowElement = buildOverflow();
			infoContainer.appendChild(overflowElement);
		}


		return [infoContainer, timeCodeElement, overflowElement];
	};

	const updateInfo = (cacheEntry, timeCode, overflow, selected) => {
		if (timeCode && timeCode[0] !== '-') cacheEntry.info.timeCode = moment.duration(timeCode).asSeconds();
		else cacheEntry.info.timeCode = null;

		if (!overflow && cacheEntry.info.overflowRef)
			cacheEntry.info.overflowRef.remove();
		else if (overflow) {
			if (!cacheEntry.info.overflowRef)
				cacheEntry.info.overflowRef = buildOverflow();

			cacheEntry.info.ref.appendChild(cacheEntry.info.overflowRef);
		}

		const formatted = getTimeCodeFormat(timeCode, fullHour);
		cacheEntry.info.timeCodeRef.innerText = formatted;
		cacheEntry.info.timeCodeFormatted = formatted;
		cacheEntry.info.ref.classList.remove('selected');
		if (selected) cacheEntry.info.ref.classList.add('selected');
	};

	const buildLineHtml = (l, idx, timeCode, forceNew = false) => {
		//l = l.replace(/ /g, '\u00a0');
		let createNew = false;
		if (idx >= cache.length || forceNew) createNew = true;
		const overflow = eaw.slice(l, 32, eaw.length(l));
		if (!createNew) {
			if (cache[idx].content.text === l) return [null, !!overflow];
			cache[idx].content.text = l;
			buildContent(cache[idx].content.ref, l, overflow);
			updateInfo(cache[idx], timeCode, overflow, selected === idx);
			return [null, !!overflow];
		}

		const cacheEntry = { content: { text: l, ref: null }, info: { ref: null, timeCode: null } };
		if (timeCode) cacheEntry.info.timeCode = moment.duration(timeCode).asSeconds();
		cacheEntry.info.timeCodeFormatted = getTimeCodeFormat(timeCode, fullHour);
		const contentContainer = document.createElement('span');
		cacheEntry.content.ref = contentContainer;

		const text = document.createElement('span');
		const overflowContent = document.createElement('span');
		overflowContent.className = classes.textOverflow;

		contentContainer.appendChild(text);
		contentContainer.appendChild(overflowContent);
		contentContainer.appendChild(document.createElement('br'));

		buildContent(contentContainer, l, overflow);


		const [container, timeCodeElement, overflowElement] = buildInfo(idx, timeCode, overflow, selected === idx);

		cacheEntry.info.ref = container;
		cacheEntry.info.timeCodeRef = timeCodeElement;
		cacheEntry.info.overflowRef = overflowElement;
		divRef.current.appendChild(contentContainer);
		lineRef.current.appendChild(container);

		return [cacheEntry, !!overflow];
	};

	const handleScroll = (e) => {
		divRef.current.scrollTop = e.target.scrollTop;
		lineRef.current.scrollTop = e.target.scrollTop;
		divRef.current.scrollLeft = e.target.scrollLeft;
		lineRef.current.scrollLeft = e.target.scrollLeft;
	};

	const handleChange = () => {
		const v = textRef.current.value;
		const lines = v.split('\n');
		let hasOverflow = false;
		lines.forEach((l, idx) => {
			l = l.replace(/ /g, '\u00a0');

			let timeCode = null;
			if (idx >= cache.length) {
				timeCode = null;
			} else {
				timeCode = cache[idx].info.timeCodeFormatted;
			}

			const [cacheEntry, overflow] = buildLineHtml(l, idx, timeCode);
			if (cacheEntry) cache.push(cacheEntry);
			hasOverflow = hasOverflow || overflow;
		});

		if (lines.length < cache.length) {
			const result = cache.splice(lines.length, cache.length);
			result.forEach(c => {
				c.content.ref.remove();
				c.info.ref.remove();
			});
		}

		setHasOverflow(hasOverflow);
		setCache(cache);
		
		// to set the width of the textarea
		textRef.current.setAttribute('style', `min-width: ${divRef.current.clientWidth + 40}px`);
	};

	const handleLineContainerClick = (e) => {

		if (disabled) {
			return;
		}
		const el = e.target.closest('.' + classes.lineNumber);
		if (!el) return;

		let currentSelection = parseInt(el.dataset.idx);
		let timeCode = getTimeCode(currentSelection);
		if (!fullHour) timeCode = `00:${timeCode}`;

		if (!timeCode && (currentSelection) !== -1) {
			return;
		}

		onTimeCodeClick(moment.duration(timeCode).asSeconds());
	};

	return (
		<Box className={classes.container}>
			<Backdrop open={loading} className={classes.loader}><Loading/></Backdrop>

			<div className={classes.common + ' ' + classes.div} ref={divRef}/>
			<textarea
				disabled={disabled || textDisabled}
				ref={textRef}
				className={classes.common + ' ' + classes.textarea}
				onChange={handleChange}
				onScroll={handleScroll}
			/>
			<div className={classes.lineContainer} ref={lineRef} onClick={handleLineContainerClick}>
			</div>
		</Box>
	);
});

export default Editor;
